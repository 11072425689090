exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-baza-wiedzy-artykul-js": () => import("./../../../src/pages/baza-wiedzy/artykul.js" /* webpackChunkName: "component---src-pages-baza-wiedzy-artykul-js" */),
  "component---src-pages-baza-wiedzy-index-js": () => import("./../../../src/pages/baza-wiedzy/index.js" /* webpackChunkName: "component---src-pages-baza-wiedzy-index-js" */),
  "component---src-pages-clean-js": () => import("./../../../src/pages/clean.js" /* webpackChunkName: "component---src-pages-clean-js" */),
  "component---src-pages-farmy-js": () => import("./../../../src/pages/farmy.js" /* webpackChunkName: "component---src-pages-farmy-js" */),
  "component---src-pages-foto-js": () => import("./../../../src/pages/foto.js" /* webpackChunkName: "component---src-pages-foto-js" */),
  "component---src-pages-fotowoltaika-js": () => import("./../../../src/pages/fotowoltaika.js" /* webpackChunkName: "component---src-pages-fotowoltaika-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-klimatyzacja-js": () => import("./../../../src/pages/klimatyzacja.js" /* webpackChunkName: "component---src-pages-klimatyzacja-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-pompy-ciepla-js": () => import("./../../../src/pages/pompy-ciepla.js" /* webpackChunkName: "component---src-pages-pompy-ciepla-js" */),
  "component---src-pages-proces-instalacji-fotowoltaiki-js": () => import("./../../../src/pages/proces-instalacji-fotowoltaiki.js" /* webpackChunkName: "component---src-pages-proces-instalacji-fotowoltaiki-js" */),
  "component---src-pages-pump-js": () => import("./../../../src/pages/pump.js" /* webpackChunkName: "component---src-pages-pump-js" */),
  "component---src-pages-realizacje-js": () => import("./../../../src/pages/realizacje.js" /* webpackChunkName: "component---src-pages-realizacje-js" */),
  "component---src-pages-thermo-js": () => import("./../../../src/pages/thermo.js" /* webpackChunkName: "component---src-pages-thermo-js" */)
}

